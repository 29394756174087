import keyMirror from "keymirror";

export const RiPanelConstants = {
    ActionTypes: keyMirror({
        TOGGLE_RI_PANEL: null,
        HANDLE_TAB_CHANGE: null,
        UPDATE_RI_PANEL_SETTINGS_DATA: null,

        /* News */
        GET_NEWS_INFO: null,
        SET_NEWS_INFO: null,
        NEWS_INFO_OPEN_SETTINGS: null,
        NEWS_INFO_CHANGE_SOURCE: null,
        NEW_INFO_RESTORE_DEFAULT_NEWS: null,
        NEWS_INFO_SET_DEFAULT_NEWS: null,
        NEWS_INFO_CHANGE_RI_NEWS: null,
        NEWS_INFO_SET_ACTIVE_ITEM: null,
        NEWS_INFO_SET_LOADING: null,

        /* Broker Reports */
        GET_BROKER_REPORTS: null,
        UPDATE_BROKER_REPORTS: null,
        SET_VISITED_ITEMS_GREY: null,
        UPDATE_TOOLTIP_STYLE: null,
        /*  */
        TOGGLE_FR_VISIBILITY: null,
        TOGGLE_FR_LAST_ONLY: null,
        UPDATE_FR_LOADING: null,
        FACTOREVENT_INFO_DATA: null,

        /* Holdings */
        UPDATE_HOLDINGS_DATA_LIST: null,
        INIT_HOLDINGS_TAB: null,

        /* SimilarFunds */
        UPDATE_SIMILAR_FUNDS_DATA: null,
        INIT_SIMILAR_FUNDS: null,
        UPDATE_SIMILAR_FUNDS_RELATIVE: null,

        /* Constituents */
        UPDATE_CONTITUENTS_DATA: null,
        INIT_CONTITUENTS_TAB: null,

        /* Check List */
        INIT_CHECKLIST_INFO: null,
        UPDATE_LIST_ITEMS: null,
        UPDATE_COLUMN_SET_INFO: null,
        ON_CHECKLIST_RESIZE: null,
        SET_CHECKLIST_ACTIVE_ITEMS: null,
        GET_CHECKLIST_DATA: null,
        UPDATE_THRESHOLD_FLAG: null,

        /* Management Info */
        GET_MANAGEMENT_INFO: null,
        MANAGEMENT_INFO_SET_DATA: null,
        MANAGEMENT_INFO_GET_RELATED_FUND_LIST: null,
        MANAGEMENT_INFO_SET_LIST_DATA: null,
        MANAGEMENT_INFO_SET_YTD_STATUS: null,
        MANAGEMENT_INFO_SET_ACTIVE_INDEX: null,
        MANAGEMENT_INFO_DETAIL_PAGE: null,

        /* Owneship Info */
        GET_OWNERSHIP_INFO: null,
        OWNERSHIP_INFO_SET_DATA: null,
        OWNERSHIP_INFO_DEFAULT_STATE: null,
        ONWERSHIP_INFO_GET_HUB_PANEL: null,
        OWNERSHIP_INFO_SET_HUB_PANEL_DATA: null,
        OWNERSHIP_INFO_HUB_PANEL_SET_INDEXES: null,
        OWNERSHIP_INFO_EXPAND_HUB_PANEL: null,
        OWNERSHIP_INFO_TOGGLE_SALES_REP_HUB_PANEL: null,
        OWNERSHIP_INFO_HUB_PANEL_SET_TOGGLE: null,
        OWNERSHIP_INFO_CHANGE_DISPALY_VIEW: null,
        OWNERSHIP_INFO_HUB_PANEL_SET_DEFAULT_STATE: null,
        OWNERSHIP_INFO_SET_HUB_USER_ROLE: null,
        OWNERSHIP_INFO_UPDATE_INDEX_CODES: null,
        OWNERSHIP_INFO_UPDATE_HUB_ROLE: null,

        /* Allocation Info */
        GET_ALLOCATION_INFO: null,
        ALLOCATION_INFO_SET_DEFAULT_STATE: null,
        ALLOCATION_INFO_SET_DATA: null,
        ALLOCATION_INFO_CHANGE_DIMENSIONS: null,

        /* Ai */
        ZZ_INDICATOR_DATA_READY: null,
        UPDATE_ZZ_INDICATOR_SETTINGS: null,
        UPDATE_ZZ_INDICATOR_SWING_SIZE: null,
        SHOW_ZZ_INDICATOR_EDIT_DIALOG: null,
        UPDATE_ZZ_INDICATOR_LINE_SETTINGS: null,
        RESTORE_ZZ_INDICATOR_LINE_SETTINGS: null,

        TOGGLE_ALERT_ABOUT_DIALOG: null,
    })
};