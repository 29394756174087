export const CorpEventsDialogConstants = {
    Split: "Split",
    Earnings: "Earnings",
    Announcement: "Announcement",
    Ceo: "Ceo",
    CorporateRepurchase: "CorporateRepurchase",
    Dividend: "Dividend",
    MajorArticles: "MajorArticles",
    AddedToIndex: "AddedToIndex",
    SecurityAnalystMeeting: "SecurityAnalystMeeting",
    ExchangeListing: "ExchangeListing",
    XDate: "XDate"
};