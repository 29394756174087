import AnnotationUtil from '../../../Utils/AnnotationUtil.js';
import { AnnouncementConstants } from '../../../Constants/PriceChartConstants.js';
import ChartVisual from "./ChartVisual.jsx";
import { connect } from 'react-redux';
import { CorpEventsType } from "../../../Constants/CorpEvents.js";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import ExtremeDataValue from "ExtremeDataValue";
import LabelText from '../Timeline/LabelText.jsx';
import React from "react";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { toggleCorpEventsClick } from '../../../Actions/DatagraphActions.js';
import { updateContextHit } from '../../../Actions/PricePanelActions.js';
import { AnnouncementDescriptionTranslateHelper, CityShortDescriptionTranslateHelper } from '../../../Utils/TranslateHelper.js';

const labelConitions = [0, 2, 3, 4, 9, 10, 13, 15];
class CorpEvents extends ChartVisual {
  constructor(props) {
    super(props);
    this.RayChartContainer = undefined;
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.onCanvasMouseLeve = this.onCanvasMouseLeve.bind(this);
    this.state = {
      showBox: false,
    };
    this.handleRightClick = this.handleRightClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleRightClick.bind(this), false);
  }

  componentDidUpdate() {
    if (this.main && !this.eventsAttached) {
      this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
      if (this.props.isPrintMode) {
        return;
      }

      this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
      this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
      this.eventsAttached = true;
    }
  }

  componentWillUnmountm() {
    if (this.props.isPrintMode) {
      return;
    }
    if (this.RayChartContainer) {
      this.RayChartContainer.removeEventListener("mousemove", this.onCanvasMouseMove, false);
      this.RayChartContainer.removeEventListener("mouseleave", this.onCanvasMouseLeve, false);
    }
    document.removeEventListener("mousedown", this.handleRightClick.bind(this), false);
  }
  GetCityShortDescription(cityCode) {
    if (CityShortDescriptionTranslateHelper[cityCode]) {
      return CityShortDescriptionTranslateHelper[cityCode];
    }
    else if (!cityCode || cityCode.Length > 3) {
      return " ";
    }

    return cityCode;
  }
  GetAnnouncementFullDescription(corpEvent) {
    if (corpEvent.CorpActionDisplayValue === AnnouncementConstants.IPO) {
      return `${AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue]}${corpEvent.Price}`
    }
    else if (AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue]) {
      return AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue];
    }
    return " ";
  }

  rectanglePath(x, y) {
    return `M ${x - 2} ${y - 15} L ${x - 2} ${y - 10} L ${x + 2} ${y - 10} L ${x + 2} ${y - 15} z`;
  }

  trianglePath(x, y) {
    return `M ${x} ${y - 16} L ${x - 3} ${y - 10} L ${x + 3} ${y - 10} ${x} ${y - 16} z`;
  }

  arrowRight(x, y) {
    return `M ${x - 2} ${y} L ${x - 8} ${y - 4} L ${x - 8} ${y + 4} L ${x - 2} ${y} z`
  }


  outputPointer(ctx, x, y) {
    ctx.moveTo(x, (y - 16));
    ctx.lineTo(x - 3, (y - 10));
    ctx.lineTo(x + 3, (y - 10));
  }

  prepareSVGPointers(item, index, textColor, isHideEpsChng) {
    return (
      <svg className="svg corporate-events">
        {item.Xlocation > 0 && item.CorpActionTypeEnum !== CorpEventsType.CorporateRepurchase.value && item.CorpActionDisplayValue !== "IPO" &&
          (item.CorpActionTypeEnum === CorpEventsType.Earnings.value ? !isHideEpsChng : true) &&
                <path d={this.trianglePath(item.Xlocation, ((!this.props.IsCollapsed ? item.Ylocation : item.Ycollapsed) - (!this.props.IsCollapsed ? (item.CorpActionTypeEnum === CorpEventsType.MajorArticles.value || item.CorpActionTypeEnum === CorpEventsType.Split.value || item.CorpActionTypeEnum === CorpEventsType.XDate.value ? 0 : 10) : 0)))}
            data-disable-track-price="true"
            fill={textColor}
            key={`trigangle${index}`}
            stroke={textColor} />}
        {item.Xlocation > 0 && item.CorpActionTypeEnum === CorpEventsType.CorporateRepurchase.value &&
          <path d={this.rectanglePath(item.Xlocation, ((!this.props.IsCollapsed ? item.Ylocation : item.Ycollapsed) - (!this.props.IsCollapsed ? (item.CorpActionTypeEnum === CorpEventsType.MajorArticles.value || item.CorpActionTypeEnum === CorpEventsType.Split.value ? 0 : 10) : 0)))}
            data-disable-track-price="true"
            fill={textColor}
            key={`rectangle${index}`}
            stroke={textColor} />}
        {item.Xlocation > 0 && item.CorpActionDisplayValue === "IPO" && item.Ylocation + 10 < this.props.dimension.height &&
          <path d={this.arrowRight(item.Xlocation, item.Ylocation)}
            data-disable-track-price="true"
            fill={textColor}
            key={`arrow${index}`}
            stroke={textColor} />}
      </svg>);
  }

  prepareSVGLabels(item, index, textColor, isHideEpsChng) {
    return (
      <svg className="svg corporate-events">
        {/* start prepareText */}
        {item.CorpActionDisplayValue && item.Xlocation > 0 && item.CorpActionDisplayValue !== "IPO" &&
          (item.CorpActionTypeEnum === CorpEventsType.Earnings.value ? !isHideEpsChng : true) &&
          <LabelText
            key={`CEO${index}`}
            textAnchor="middle"
            isHighlighted={true}
            textValue={item.CorpActionTypeEnum === CorpEventsType.Split.value ? item.splitDisplay :
              item.CorpActionTypeEnum === CorpEventsType.SecurityAnalystMeeting.value ? this.getMeetingDate(item) :
                item.CorpActionTypeEnum === CorpEventsType.Ceo.value ? "CEO" :
                  item.CorpActionTypeEnum === CorpEventsType.CoCeo.value ? "COCEO" :
                    item.CorpActionTypeEnum === CorpEventsType.AddedToindex.value ? "500" :
                      item.CorpActionTypeEnum === CorpEventsType.EDate.value ? "DUE" :
                        item.CorpActionTypeEnum === CorpEventsType.Announcement.value && item.CorpActionDisplayValue === "TRADE SUSP" ? "SUSP" :
                          item.CorpActionTypeEnum === CorpEventsType.Announcement.value ? item.CorpActionDisplayValue === "TRADE RSUM" ? "RSUM" :
                            item.Price ? (item.currency + (item.Price > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.Price, 1) : ExtremeDataValue.showPrice(item.Price))) : "" : item.CorpActionDisplayValue}
            dx="0"
            dy="0"
            style={{
              font: 'calibri',
              fontSize: '10px',
              fill: item.CorpActionTypeEnum === CorpEventsType.Earnings.value ? item.Uptick ? this.props.posDTColor : this.props.negDTColor : textColor
            }}
            textPosX={item.Xlocation}
            textPosY={item.Ylocation} />}
        {item.CorpActionDisplayValue && item.Xlocation > 0 && item.CorpActionDisplayValue === "IPO" && item.Ylocation + 10 < this.props.dimension.height &&
          <LabelText
            key={`IPO${index}`}
            textAnchor="end"
            isHighlighted={true}
            textValue={item.IPOChange ? `${item.IPOChange.toFixed(2)}%` : ""}
            dx="0"
            dy="0"
            style={{
              font: 'calibri',
              fontSize: '10px',
              fill: textColor
            }}
            textPosX={item.Xlocation - 10}
            textPosY={item.Ylocation + 11} />}
        {/* end prepareText */}

        {/* start prepareLabels */}
        {item.Xlocation > 0 && (labelConitions.includes(item.CorpActionTypeEnum) || (item.CorpActionTypeEnum === CorpEventsType.Earnings.value && !isHideEpsChng)) &&
          <LabelText
            key={`EPS${index}`}
            textAnchor="middle"
            isHighlighted={true}
            textValue={(item.CorpActionTypeEnum === CorpEventsType.CorporateRepurchase.value || item.CorpActionTypeEnum === CorpEventsType.RepurchaseAnnouncement.value) ? "BUYBK" :
              item.CorpActionTypeEnum === CorpEventsType.SecurityAnalystMeeting.value ? this.GetCityShortDescription(item.CorpActionDisplayValue) :
                item.CorpActionTypeEnum === CorpEventsType.Dividend.value ? "DIV" :
                  item.CorpActionTypeEnum === CorpEventsType.AddedToindex.value ? "S&P" :
                    item.CorpActionTypeEnum === CorpEventsType.EDate.value ? "EPS" :
                    (item.CorpActionTypeEnum === CorpEventsType.Ceo.value || item.CorpActionTypeEnum === CorpEventsType.CoCeo.value) ? "NEW" :
                       item.CorpActionTypeEnum === CorpEventsType.Earnings.value  ? "EPS" : ""}
            dx="0"
            dy="0"
            style={{
              font: 'calibri',
              fontSize: '10px',
              fill: textColor
            }}
            textPosX={item.Xlocation}
            textPosY={item.Ylocation - 10} />}
        {/* end prepareLabels */}
        {/* start prepareAnnouncements */}
        {item.CorpActionDisplayValue && item.Xlocation > 0 && item.CorpActionTypeEnum === CorpEventsType.Announcement.value && item.CorpActionDisplayValue !== "IPO" &&
          <LabelText
            key={`TRADE${index}`}
            textAnchor="middle"
            isHighlighted={true}
            textValue={(item.CorpActionDisplayValue === "TRADE SUSP" || item.CorpActionDisplayValue === "TRADE RSUM") ? "TRADE" : item.CorpActionDisplayValue}
            dx="0"
            dy="0"
            style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
            textPosX={item.Xlocation}
            textPosY={item.Ylocation - 10} />}
        {item.CorpActionDisplayValue && item.Xlocation > 0 && item.CorpActionTypeEnum === CorpEventsType.Announcement.value && item.CorpActionDisplayValue === "IPO" && item.toolTip !== undefined &&
          <LabelText
            key={`Announcement${index}`}
            textAnchor="end"
            isHighlighted={true}
            textValue={`IPO ${item.currency}${item.Price > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.Price, 1) : ExtremeDataValue.showPrice(item.Price)}`}
            dx="0"
            dy="0"
            style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
            textPosX={item.Xlocation - 10}
            textPosY={item.Ylocation + 10 > this.props.dimension.height ? this.props.dimension.height - 20 : item.Ylocation + 3} />}
        {/* end prepareAnnouncements */}
      </svg>
    );
  }
  getMeetingDate(chartdata) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const actionDate = chartdata.CorpActionDate.getFullYear
      ? chartdata.CorpActionDate
      : new Date(parseInt(chartdata.CorpActionDate.substr(6)));
    return `${monthNames[actionDate.getMonth()]} ${actionDate.getDate()}`;
  }

  preparePointers(chartData, useCanvas) {
    if (!this.mainCanvas || !chartData) {
      return;
    }
    let tlLength = chartData.length;
    const ctx = this.mainCanvas.getContext("2d");
    const epsDown = [];
    const epsUp = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    ctx.canvas.height = this.props.dimension.height;
    ctx.canvas.width = this.props.dimension.width;
    ctx.clearRect(0, 0, this.props.dimension.width, this.props.dimension.height);
    if (!chartData || chartData.length < 1) {
      return;
    }

    ctx.fillStyle = ThemeHelper.getThemedBrush("scaleText");
    ctx.beginPath();
    ctx.font = "8pt Calibri";
    ctx.fontWeight = "bold";

    const isHideEpsChng = DatagraphStore.isHideEpsChng();
    for (let j = 0; j < tlLength; j++) {
      const chartdata = chartData[j];
      const x = chartdata.Xlocation;
      const displayValue = chartdata.CorpActionDisplayValue;
      let y = this.props.IsCollapsed || displayValue === "IPO" ? chartdata.Ylocation : chartdata.Ycollapsed;
      if (y <= 0) {
        continue;
      }
      ctx.textAlign = "center";
      const yVal = y - (this.props.IsCollapsed ? 10 : 0);
      switch (chartdata.CorpActionTypeEnum) {
        case CorpEventsType.MajorArticles.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, y);
          }
          break;
        case CorpEventsType.RepurchaseAnnouncement.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("BUYBK", x, yVal);
            }
            y = yVal + 8;
          }
          break;
        case CorpEventsType.Dividend.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("DIV", x, yVal);
            }
            y = yVal + 8;
          }
          break;
        case CorpEventsType.EDate.value:
            if (useCanvas) {
                this.outputPointer(ctx, x, yVal);
            }
            if (this.props.IsCollapsed) {
                if (useCanvas) {
                    ctx.fillText("EPS", x, yVal);
                }
                y = yVal + 8;
                if (useCanvas) {
                    ctx.fillText("DUE", x, y);
                }
            }
            continue;
        case CorpEventsType.AddedToindex.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("S&P", x, yVal);
            }
            y = yVal + 8;
            if (useCanvas) {
              ctx.fillText("500", x, y);
            }
          }
          continue;
        case CorpEventsType.Announcement.value:
          if (displayValue === "IPO") {
            let showArrow = true;
            if (y + 10 > this.props.dimension.height) {
              y = this.props.dimension.height - 20;
              showArrow = false;
            }
            if (chartdata.Price > 0) {
              if (showArrow && useCanvas) {
                ctx.moveTo(x - 2, y);
                ctx.lineTo(x - 8, (y - 4));
                ctx.lineTo(x - 8, (y + 4));
              }
              const dispValue = chartdata.Price > 999 ? ExtremeDataValue.abbreviateFinancialValue(chartdata.Price, 1) : ExtremeDataValue.showPrice(chartdata.Price);
              let cDisplay = `${displayValue} ${chartdata.currency} ${dispValue}`;
              ctx.textAlign = "end";
              if (useCanvas) {
                ctx.fillText(cDisplay, x - 10, y + 3);
              }
              if (chartdata.IPOChange) {
                y += 11;
                cDisplay = `${chartdata.IPOChange.toFixed(2)}%`;
                ctx.textAlign = "end";
                if (useCanvas) {
                  ctx.fillText(cDisplay, x - 10, y);
                }
              }
            }
          }
          else {
            if (useCanvas) {
              this.outputPointer(ctx, x, yVal);
            }

            if (this.props.IsCollapsed) {
              const dispValue = chartdata.Price > 999 ? ExtremeDataValue.abbreviateFinancialValue(chartdata.Price, 1) : ExtremeDataValue.showPrice(chartdata.Price);

              const trade = displayValue === "TRADE SUSP" || displayValue === "TRADE RSUM";
              if (displayValue !== "") {
                if (useCanvas) {
                  if (trade) {
                    ctx.fillText("TRADE", x, yVal);
                  } else {
                    ctx.fillText(displayValue, x, yVal);
                  }
                }
                if (chartdata.Price && displayValue !== "LPE" || trade) {
                  y = yVal + 8;
                  if (trade) {
                    if (useCanvas) {
                      if (displayValue === "TRADE SUSP") {
                        ctx.fillText("SUSP", x, y);
                      } else {
                        ctx.fillText("RSUM", x, y);
                      }
                    }
                  } else {
                    if (useCanvas) {
                      ctx.fillText(`${chartdata.currency} ${dispValue}`, x, y);
                    }
                  }
                }
                chartdata.toolTip2 = undefined;
              }
            }
          }
          continue;
        case CorpEventsType.Ceo.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("NEW", x, yVal);
            }
            y = yVal + 8;
            if (useCanvas) {
              ctx.fillText("CEO", x, y);
            }
          }
          continue;
        case CorpEventsType.CoCeo.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("NEW", x, yVal);
            }
            y = yVal + 8;
            if (useCanvas) {
              ctx.fillText("CoCEO", x, y);
            }
          }
          continue;
        case CorpEventsType.Earnings.value:
          if (isHideEpsChng) {
            continue;
          }
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("EPS", x, yVal);
            }
            if (useCanvas) {
              if (chartdata.Uptick) {
                epsUp.push(chartdata);
              } else if (!chartdata.Uptick) {
                epsDown.push(chartdata);
              }
            }
          }
          continue;
        case CorpEventsType.InsiderBuy.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, y);
          }
          break;
        case CorpEventsType.InsiderSell.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, y);
          }
          break;
        case CorpEventsType.SecurityAnalystMeeting.value:
          if (useCanvas) {
            this.outputPointer(ctx, x, yVal);
          }

          if (this.props.IsCollapsed) {
            const city = this.GetCityShortDescription(displayValue);
            if (useCanvas) {
              ctx.fillText(city, x, yVal);
            }

            const actionDate = chartdata.CorpActionDate.getFullYear ? chartdata.CorpActionDate : new Date(parseInt(chartdata.CorpActionDate.substr(6)));
            y = yVal + 8;
            if (useCanvas) {
              ctx.fillText(`${monthNames[actionDate.getMonth()]} ${actionDate.getDate()}`, x, y);
            }
          }
          continue;
        case CorpEventsType.Split.value:
          if (chartdata.splitDisplay) {
            if (useCanvas) {
              this.outputPointer(ctx, x, y);
            }
            if (this.props.IsCollapsed) {
              if (useCanvas) {
                ctx.fillText(chartdata.splitDisplay, x, y);
              }
            }
          }
          continue;
        case CorpEventsType.CorporateRepurchase.value:
          if (useCanvas) {
            ctx.moveTo(x - 3, (yVal - 16));
            ctx.lineTo(x - 3, (yVal - 10));
            ctx.lineTo(x + 3, (yVal - 10));
            ctx.lineTo(x + 3, (yVal - 16));
          }
          if (this.props.IsCollapsed) {
            if (useCanvas) {
              ctx.fillText("BUYBK", x, yVal);
            }
            y = yVal + 8;
          }
          break;
        default:
          if (useCanvas) {
            this.outputPointer(ctx, x, y);
          }
          break;
      }
      if (this.props.IsCollapsed) {
        if (displayValue !== "" && x > 0 && y > 0) {
          if (useCanvas) {
            ctx.fillText(displayValue, x, y);
          }
        }
      }
    }
    ctx.fill();
    ctx.closePath();
    tlLength = epsDown.length;
    if (tlLength > 0) {
      ctx.font = "8pt Calibri";
      ctx.textAlign = "center";
      ctx.fillStyle = this.props.negDTColor;
      ctx.beginPath();
      for (let j = 0; j < tlLength; j++) {
        const x = epsDown[j].Xlocation;
        const y = epsDown[j].Ylocation;
        const displayValue = epsDown[j].CorpActionDisplayValue;

        if (useCanvas) {
          ctx.fillText(displayValue, x, y);
        }
      }
      ctx.fill();
      ctx.closePath();
    }
    tlLength = epsUp.length;
    if (tlLength > 0) {
      ctx.font = "8pt Calibri";
      ctx.textAlign = "center";
      ctx.fillStyle = this.props.posDTColor;
      ctx.beginPath();
      for (let j = 0; j < tlLength; j++) {
        const x = epsUp[j].Xlocation;
        const y = epsUp[j].Ylocation;
        const displayValue = epsUp[j].CorpActionDisplayValue;

        if (useCanvas) {
          ctx.fillText(displayValue, x, y);
        }
      }
      ctx.fill();
      ctx.closePath();
    }
  }
  showInfoBox(showText, showText2, screenX, screenY, backgroundColor) {
    const chartHeight = this.props.dimension.height;
    const xLeft = `${screenX}px`;
    const ytop = screenY > chartHeight - 40 ? showText2 ? `${(screenY - 40)}px` : `${(screenY - 20)}px` : `${screenY + 20}px`;
    const height = showText2 !== undefined ? "40px" : "20px";
    const width = "auto";
    const color = ThemeHelper.getFontColor(backgroundColor);
    const textStyle = {
      backgroundColor,
      borderColor: "#333333",
      borderStyle: "solid",
      borderWidth: "1px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      fontFamily: "calibri",
      color,
      position: "absolute",
      left: xLeft,
      top: ytop,
      height: height,
      width: width,
      zIndex: 99,
      whiteSpace: 'nowrap'
    };
    return (
      <div style={textStyle} key={screenX} >
        <div style={{
          textAlign: "Center",
          fontFamily: "calibri",
          color,
          marginLeft: '6px',
          marginRight: '6px',
        }}>
          <p>{showText}</p>
          {showText2 && <p style={{ marginTop: "-12px" }}>{showText2}</p>}
        </div>
      </div>
    );
  }
  onCanvasMouseLeve() {
    setTimeout(() => {
      this.setState({ showBox: false, screenX: -1, screenY: -1 });
    }, 0);
  }
  onCanvasMouseMove(e) {
    clearTimeout(this.mouseMoveTimeout);
    if (AnnotationUtil.isAnnotationsTabActive() && !AnnotationUtil.isPointerSelected || !this.props.showToolTip) {
      return;
    }
    this.mouseMoveTimeout = setTimeout(() => {
      const chartHeight = this.props.dimension.bottom;
      let xAxis = parseInt(e.clientX - this.props.dimension.left);
      let yAxis = parseInt(e.clientY - this.props.dimension.top);
      const isHit = this.hitTest(xAxis, yAxis);
      if (isHit) {
        const len = isHit.toolTip ? isHit.toolTip.length * 6 : 0;
        xAxis = xAxis - len;
        if (xAxis < len) {
          xAxis = xAxis + len;
        }
        if (chartHeight - yAxis < 40) {
          yAxis = yAxis - 40;
        }
        if (len > 0) {
          this.setState({
            showBox: true,
            screenX: xAxis,
            screenY: yAxis,
            displayText: isHit.toolTip,
            displayText2: isHit.toolTip2
          });
        }
      }
      else {
        this.setState({ showBox: false });
      }
    }, 0);
  }

  hitTest(x, y) {
    //Fix for PANWEB - 1068 - Check if any modal open
    const openModals = document.getElementsByClassName('modal-open');
    if (openModals.length >= 1) {
      return false;
    }
    const ishit = this.isPointInStroke(x, y);
    return ishit;
  }

  isPointInStroke(x, y) {
    if(!this.props.corpeventsData){
      return null
    }
    const prcLength = this.props.corpeventsData.length;
    for (let j = 0; j < prcLength; j++) {
      const linePoint = this.props.corpeventsData[j];
      const yLocation = !this.props.IsCollapsed || linePoint?.CorpActionDisplayValue === "IPO" ? "Ylocation" : "Ycollapsed";
      if (linePoint &&
        (linePoint.Xlocation - 6 <= x && linePoint.Xlocation + 6 >= x) &&
        (linePoint[yLocation] - 25 <= y && linePoint[yLocation] >= y)) {
        return linePoint;
      }
    }
    return null;
  }

  handleRightClick(e) {
    if (e.which !== 3) {
      return;
    }
    const xAxis = parseInt(e.clientX - this.props.dimension.left);
    const yAxis = parseInt(e.clientY - this.props.dimension.top);
    const isHit = this.hitTest(xAxis, yAxis);

    if (isHit) {
      this.props.updateContextHit({})
    }
    e.preventDefault();
    return false;
  }

  render() {
    TimeTrackingWindow.trackChartLoadingTime();
    const textColor = ThemeHelper.getThemedBrush("scaleText");
    const isHideEpsChng = DatagraphStore.isHideEpsChng();
    return (
      this.props.isCorpEventVisible && this.props.corpeventsData ? <>
        <button className="chart-corp-event-btn" data-disable-track-price="true" onClick={this.props.toggleCorpEventsClick} >{this.props.IsCollapsed ? '+' : '-'}</button>
        <div ref={(ref) => (this.main = ref)} id="g" >
          {this.props.useCanvas && <canvas className="chartVisual" ref={(ref) => (this.mainCanvas = ref)}
            style={{
              fitPosition: "fill", width: "100%", height: "100%", position: "absolute"
            }}>
            {this.preparePointers(this.props.corpeventsData, this.props.useCanvas)}
          </canvas>}
          {!this.props.useCanvas && this.props.corpeventsData.length > 0 && this.props.corpeventsData.map((item, index) => <React.Fragment key={index}>
              {!this.props.IsCollapsed && this.prepareSVGLabels(item, index, textColor, isHideEpsChng)}
              {this.prepareSVGPointers(item, index, textColor, isHideEpsChng)}
            </ React.Fragment>)}
          {this.state.showBox &&this.showInfoBox(this.state.displayText, this.state.displayText2, this.state.screenX, this.state.screenY, "#FFFFDD")}
        </div>
      </> : <></>
    );
  }
}
const mapStateToProps = ({ DatagraphReducers, appColor }) => {
  const { isCorpEventVisible, IsCollapsed, ShowCorpEvents, corpeventsData } = DatagraphReducers.CorpEventsReducer;
  const { showToolTip, useCanvas } = DatagraphReducers.DataGraphReducer;
  const { dimension } = DatagraphReducers.PriceChartReducer;
  const { isThemeChanged, posDTColor, negDTColor } = appColor
  return ({ isCorpEventVisible, IsCollapsed, ShowCorpEvents, showToolTip, corpeventsData, useCanvas, dimension, isThemeChanged, posDTColor, negDTColor });
}

const mapDispatchToProps = (dispatch) => ({
  toggleCorpEventsClick: (IsCollapsed) => dispatch(toggleCorpEventsClick(IsCollapsed)),
  updateContextHit: (IsCollapsed) => dispatch(updateContextHit(IsCollapsed))
});
export default connect(mapStateToProps, mapDispatchToProps)(CorpEvents);