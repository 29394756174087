import BlockType from "../../../Constants/BlockType.js";
import consoleStore from "../../../Stores/ConsoleWindow/ConsoleStore.js";
import ContextMenuConstants from "ContextMenuConstants";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants.js";
import FeatureModuleType from "../../../Constants/FeatureModuleType.js";
import { FMIndicatorConst } from "./Indicators/FundamentalIndicatorSaga.js";
import { getDatagraphAlertStates } from "../../../Reducers/Alert/selector.js";
import GraphComponent from "../../../Constants/GraphComponent.js";
import GraphType from "../../../Constants/GraphType.js";
import LocalizationStore from "../../../Stores/Localization/LocalizationStore.js";
import { MenuDialogConstants } from "../../../Constants/MenuDialogConstants.js";
import { OneilRatingLineTypes } from "./Indicators/OneilRatingSaga.js";
import { openAlertAboutDialog } from "../../../Actions/RelatedInfoPanelActions/RiPanelActions.js";
import { openCustomTIModal } from "../../../Actions/CustomTIModalActions.js";
import { openExternalDataEditDialog } from "../../../Actions/ExternalDataUploaderActions.js";
import SmartViewType from "../../../Constants/SmartViewType.js";
import TechnicalIndicator from "../../../Constants/TechnicalIndicator.js";
import { updateCommonAboutDialog } from "../../../Actions/DatagraphActions.js";
import { createMovingAverageAlert, deleteAlert, openAndAlertDialog, openORAlertDialog, openPriceAlertDialog, toggleAlertStatus, updateCurrentEditingAlert } from '../../../Actions/AlertActions';
import { getContextMenuStates, getDatagraphStates, priceChartReducerselect } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper, ORatingTranslateHelper } from "../../../Utils/TranslateHelper.js";
import { IndicatorContextConst, IndicatorLineConst } from "../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants.js";
import { openEPSAnimationDialog, openIndexLineDialog, openMALineSettingDialog, openRSLineDialog, priceMenuItemClicked, showFundamentalLineDialog } from '../../../Actions/PricePanelActions';
import { PriceChartConst, PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { showZZIndicatorEditDialog } from "../../../Actions/RelatedInfoPanelActions/RiPanelAiActions.js";

import { put, select, takeLatest } from 'redux-saga/effects';
import { toggleFundamentalLine, toggleORatingLine } from "../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";
const { ActionTypes } = PriceChartConstants;

function* onContextMenuClick({ item }) {
    const { SymbolInfo, currencyStr } = yield select(getDatagraphStates)
    //Hide context menu
    yield put({
        type: ActionTypes.HIDE_PRICE_CONTEXT_MENU
    });
    const { contextMenuXPos, contextMenuYPos, priceLocation } = yield select(getContextMenuStates);
    switch (item.action) {
        case ContextMenuConstants.OR_CONDITION_ALERTS:
            yield put(openORAlertDialog(SymbolInfo.MsId, SymbolInfo.Osid, SymbolInfo.Symbol, currencyStr))
            break;
        case ContextMenuConstants.PRICE_ALERT: {
            const { scale } = yield select(priceChartReducerselect);
            let priceAtLocation = priceLocation === 0 ? 0 : scale.ComputePrice(priceLocation);
            priceAtLocation = priceAtLocation > 1 ? priceAtLocation.toFixed(2) : priceAtLocation.toFixed(3);
            yield put(openPriceAlertDialog(null, false, priceAtLocation, priceLocation ? contextMenuXPos : null, priceLocation ? contextMenuYPos : null))
            break;
        }
        case ContextMenuConstants.CREATE_MA_ALERT:
            yield put(createMovingAverageAlert(item));
            break;
        case ContextMenuConstants.EDIT_SETTINGS:
            if (item.lineType === PriceChartConst.ZZ_INDICATOR) {
                yield put(showZZIndicatorEditDialog());
            }
            else {
                yield put(showFundamentalLineDialog({ ...item }))
            }
            break;
        case ContextMenuConstants.EDIT_ANIMATION_SETTINGS:
            yield put(openEPSAnimationDialog(item))
            break;
        case ContextMenuConstants.EARNINGS_LINE_ANIMATION:
            break;
        case ContextMenuConstants.HIDE_EARNINGS_LINE:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.EDIT_RPS_SETTINGS:
            yield put(showFundamentalLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_RPS_LINE:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.EDIT_BV_SETTINGS:
            yield put(showFundamentalLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_BV_LINE:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.EDIT_CFS_SETTINGS:
            yield put(showFundamentalLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_CFS_LINE:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.EDIT_DIV_SETTINGS:
            yield put(showFundamentalLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_DIV_LINE:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.EDIT_FCF_SETTINGS:
            yield put(showFundamentalLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_FCF_LINE:
            yield put(priceMenuItemClicked({ ...item }));
            break;
        case ContextMenuConstants.EDIT_INDEX_SETTINGS:
            yield put(openIndexLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_INDEX_LINE:
            yield put(priceMenuItemClicked({ ...item }));
            break;
        case ContextMenuConstants.EDIT_RS1_SETTINGS:
            yield put(openRSLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_RS1_LINE:
            yield put(priceMenuItemClicked({ ...item }));
            break;
        case ContextMenuConstants.EDIT_RS2_SETTINGS:
            yield put(openRSLineDialog({ ...item }))
            break;
        case ContextMenuConstants.HIDE_RS2_LINE:
            yield put(priceMenuItemClicked({ ...item }));
            break;
        case MenuDialogConstants.ActionTypes.OPEN_MOVING_AVERAGE_DIALOG:
            yield put(openMALineSettingDialog({ ...item }));
            break;
        case ContextMenuConstants.HIDE_MA_LINE:
            yield put(priceMenuItemClicked({ ...item }));
            break;
        case ContextMenuConstants.HIDE_ZZ_INDICATOR:
            yield put(priceMenuItemClicked({ ...item }))
            break;
        case ContextMenuConstants.UPDATE_PRICE_ALERT: {
            const { currentEditingAlert } = yield select(getDatagraphAlertStates);
            yield put(openPriceAlertDialog(currentEditingAlert, true));
            yield put(updateCurrentEditingAlert(null))
            break;
        }
        case ContextMenuConstants.DELETE_ALERT: {
            const { currentEditingAlert } = yield select(getDatagraphAlertStates)
            yield put(deleteAlert(currentEditingAlert));
            yield put(updateCurrentEditingAlert(null))
            break;
        }
        case ContextMenuConstants.PRICE_ALERT_TRIGGERED_ACTIVE: {
            const { currentEditingAlert } = yield select(getDatagraphAlertStates)
            yield put(toggleAlertStatus(currentEditingAlert))
            yield put(updateCurrentEditingAlert(null))
            break;
        }
        case ContextMenuConstants.PRICE_ALERT_DELETE: {
            const { currentEditingAlert } = yield select(getDatagraphAlertStates);
            currentEditingAlert.isTriggered = 0;
            yield put(updateCurrentEditingAlert(null));
            break;
        }
        case ContextMenuConstants.AND_CONDITION_ALERTS:
            yield put(openAndAlertDialog(SymbolInfo.MsId, SymbolInfo.Osid, SymbolInfo.Symbol, currencyStr))
            break;

        case ContextMenuConstants.ALERT_ABOUT:
            yield put(openAlertAboutDialog(true))
            break;
        case ContextMenuConstants.ABOUT:
            yield put(updateCommonAboutDialog(true, item.url, item.title))
            break;
        case IndicatorContextConst.Indicators:
            yield put(openCustomTIModal({ isShowModal: true, selectedOption: item.selectedOption, selectedTiTab: item.selectedTiTab }))
            break;
        case IndicatorContextConst.External_Indicators:
            yield put(openExternalDataEditDialog({ header: item.header, listId: item.listId }, false))
            break;
        case BlockType.TechIndicator:
            yield put(toggleORatingLine(item.lineType));
            break;
        case BlockType.EPSR:
            yield put(toggleFundamentalLine(item.action, item.lineType));
            break;
        case BlockType.PTOE:
            yield put(toggleFundamentalLine(item.action, item.lineType));
            break;
        case BlockType.PTOS:
            yield put(toggleFundamentalLine(item.action, item.lineType));
            break;
        default:
            break;
    }
}

const contextMenuTranslateHelper = {
    [ContextMenuConstants.PRICE_ALERT]: LocalizationStore.getTranslatedData("ch_pral", ContextMenuConstants.PRICE_ALERT),
    [ContextMenuConstants.AND_CONDITION_ALERTS]: LocalizationStore.getTranslatedData("ch_msa_sup_aca", ContextMenuConstants.AND_CONDITION_ALERTS),
    [ContextMenuConstants.OR_CONDITION_ALERTS]: LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.OR_CONDITION_ALERTS),
    [ContextMenuConstants.UPDATE_PRICE_ALERT]: LocalizationStore.getTranslatedData("ch_editAlert", ContextMenuConstants.UPDATE_PRICE_ALERT),
    [ContextMenuConstants.DELETE_ALERT]: LocalizationStore.getTranslatedData("ch_DelAlt", ContextMenuConstants.DELETE_ALERT),
    [ContextMenuConstants.PRICE_ALERT_TRIGGERED_ACTIVE]: LocalizationStore.getTranslatedData("ch_reactivateAlert", ContextMenuConstants.PRICE_ALERT_TRIGGERED_ACTIVE),
    [ContextMenuConstants.PRICE_ALERT_DELETE]: LocalizationStore.getTranslatedData("ch_removeAlertIcon", "Remove Alert Icon"),
    [MenuDialogConstants.ActionTypes.OPEN_MOVING_AVERAGE_DIALOG]: LocalizationStore.getTranslatedData("ch_editstgs", "Edit Settings"),
    [ContextMenuConstants.CREATE_MA_ALERT]: LocalizationStore.getTranslatedData("ch_alert", ContextMenuConstants.CREATE_MA_ALERT),
    [ContextMenuConstants.HIDE_MA_LINE]: LocalizationStore.getTranslatedData("ch_hide", "Hide "),

}
const ChartMenuConst = [ContextMenuConstants.PRICE_ALERT, ContextMenuConstants.AND_CONDITION_ALERTS, ContextMenuConstants.OR_CONDITION_ALERTS];

const priceAlertActiveTriggeredConst = [ContextMenuConstants.UPDATE_PRICE_ALERT, ContextMenuConstants.DELETE_ALERT];
const priceAlertInActiveTriggeredConst = [ContextMenuConstants.PRICE_ALERT_TRIGGERED_ACTIVE, ContextMenuConstants.PRICE_ALERT_DELETE];

const MAAlertInActiveTriggeredConst = [ContextMenuConstants.PRICE_ALERT_TRIGGERED_ACTIVE, ContextMenuConstants.PRICE_ALERT_DELETE];

const IndicatorSettingTypes = [BlockType.EPSR, BlockType.PTOE, BlockType.PTOS, BlockType.TechIndicator, BlockType.MACD, BlockType.RSI, BlockType.Stochastics, BlockType.WonStochastics];
const IndicatorAppearanceTypes = [BlockType.EPSR, BlockType.PTOE, BlockType.PTOS, BlockType.TechIndicator, BlockType.MACD, BlockType.RSI, BlockType.Stochastics, BlockType.WonStochastics, BlockType.AccDist];
const IndicatorAboutTypes = [BlockType.PTOE, BlockType.PTOS, BlockType.AccDist];

function* populateContextMenuItems() {
    try {
        const { viewsSettings, majorPeriodicity, isIntraday, SymbolType, SymTypeEnum } = yield select(getDatagraphStates)
        const maSettings = viewsSettings.MASettings[majorPeriodicity];
        let contextMenuOptions = {};
        contextMenuOptions[ContextMenuConstants.ALERT_MENU] = [];
        if (![SmartViewType.FUND, SmartViewType.CASHSPOTS, SmartViewType.FUTURES, SmartViewType.CURRENCY].includes(SymbolType)) {
            contextMenuOptions[ContextMenuConstants.ALERT_MENU] = ChartMenuConst.map((action) => ({
                headerText: contextMenuTranslateHelper[action],
                action,
                isEnabled: true
            }));
        }
        
        contextMenuOptions[ContextMenuConstants.PRICE_ACTIVE_TRIGGERED_MENU] = priceAlertActiveTriggeredConst.map((action) => ({
            headerText: contextMenuTranslateHelper[action],
            action: action,
            isEnabled: true
        }));
        contextMenuOptions[ContextMenuConstants.PRICE_IN_ACTIVE_TRIGGERED_MENU] = priceAlertInActiveTriggeredConst.map((action) => ({
            headerText: contextMenuTranslateHelper[action],
            action: action,
            isEnabled: true
        }));

        contextMenuOptions[ContextMenuConstants.MA_ACTIVE_TRIGGERED_MENU] = [{
            headerText: contextMenuTranslateHelper[ContextMenuConstants.DELETE_ALERT],
            action: ContextMenuConstants.DELETE_ALERT,
            isEnabled: true
        }];
        contextMenuOptions[ContextMenuConstants.MA_IN_ACTIVE_TRIGGERED_MENU] = MAAlertInActiveTriggeredConst.map((action) => ({
            headerText: contextMenuTranslateHelper[action],
            action,
            isEnabled: true
        }));

        // Moving Averages
        contextMenuOptions[ContextMenuConstants.MA_MENU] = [];

        //let i = 0;
        
        maSettings.forEach((item, index) => {
            const movingAveragesLineMenuItem = [];
            movingAveragesLineMenuItem.push({
                headerText: contextMenuTranslateHelper[MenuDialogConstants.ActionTypes.OPEN_MOVING_AVERAGE_DIALOG],
                action: MenuDialogConstants.ActionTypes.OPEN_MOVING_AVERAGE_DIALOG,
                isEnabled: true,
                itemNo: index,
                maSettingsObj: item
            })

            //Create MA Alert option should not be present for Current & Fund types.
            if ((!isIntraday && ![SmartViewType.FUND, SmartViewType.CASHSPOTS, SmartViewType.CRYPTOCURRENCY, SmartViewType.FUTURES, SmartViewType.CURRENCY].includes(SymbolType))) {
                movingAveragesLineMenuItem.push({
                    headerText: contextMenuTranslateHelper[ContextMenuConstants.CREATE_MA_ALERT],
                    action: ContextMenuConstants.CREATE_MA_ALERT,
                    isEnabled: true,
                    isCheckable: true,
                    isChecked: item.AlertIsActive,
                    maSettingsObj: item
                })
            }

            movingAveragesLineMenuItem.push({
                headerText: `${contextMenuTranslateHelper[ContextMenuConstants.HIDE_MA_LINE]}${item.ma} ${item.maType}`,
                action: ContextMenuConstants.HIDE_MA_LINE,
                isEnabled: true,
                graphComponent: GraphComponent.mAverage,
                isActive: true,
                itemNo: index,
                maSettingsObj: item
            })
            contextMenuOptions[ContextMenuConstants.MA_MENU].push(movingAveragesLineMenuItem)
        });

        //Fundamental Lines Context Menu
        if (SmartViewType.INDEX === SymbolType || SmartViewType.STOCK === SymbolType) {
            contextMenuOptions = { ...contextMenuOptions, [PriceChartConst.EPS]: [], [PriceChartConst.RPS]: [], [PriceChartConst.BV]: [], [PriceChartConst.CFS]: [], [PriceChartConst.DIV]: [], [PriceChartConst.FCF]: [] }

            contextMenuOptions.epsAnimationSettings = viewsSettings.EPSAnimationSettings;
            contextMenuOptions[PriceChartConst.EPS].push({
                headerText: ContextMenuConstants.EDIT_SETTINGS,
                action: ContextMenuConstants.EDIT_SETTINGS,
                graphComponent: GraphComponent.EPSLine,
                lineType: PriceChartConst.EPS,
                isEnabled: true,
            });

            if (majorPeriodicity === GraphType.Daily || majorPeriodicity === GraphType.Weekly || majorPeriodicity === GraphType.Monthly) {
                contextMenuOptions[PriceChartConst.EPS].push({
                    headerText: ContextMenuConstants.EARNINGS_LINE_ANIMATION,
                    action: ContextMenuConstants.EARNINGS_LINE_ANIMATION,
                    graphComponent: GraphComponent.EPSAnimation,
                    isActive: contextMenuOptions.epsAnimationSettings[majorPeriodicity].IsEarningsAnimationDisplayed,
                    isEnabled: true,
                });
                contextMenuOptions[PriceChartConst.EPS].push({
                    headerText: ContextMenuConstants.EDIT_ANIMATION_SETTINGS,
                    action: ContextMenuConstants.EDIT_ANIMATION_SETTINGS,
                    lineType: PriceChartConst.EPS_ANI,
                    isEnabled: true,
                })
            }
            contextMenuOptions[PriceChartConst.EPS].push({
                headerText: ContextMenuConstants.HIDE_EARNINGS_LINE,
                action: ContextMenuConstants.HIDE_EARNINGS_LINE,
                graphComponent: GraphComponent.EPSLine,
                lineType: PriceChartConst.EPS,
                isActive: true,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.RPS].push({
                headerText: ContextMenuConstants.EDIT_RPS_SETTINGS,
                action: ContextMenuConstants.EDIT_RPS_SETTINGS,
                graphComponent: GraphComponent.RPSLine,
                lineType: PriceChartConst.RPS,
                isEnabled: true,
            })
            contextMenuOptions[PriceChartConst.RPS].push({
                headerText: ContextMenuConstants.HIDE_RPS_LINE,
                action: ContextMenuConstants.HIDE_RPS_LINE,
                graphComponent: GraphComponent.RPSLine,
                lineType: PriceChartConst.RPS,
                isActive: true,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.BV].push({
                headerText: ContextMenuConstants.EDIT_BV_SETTINGS,
                action: ContextMenuConstants.EDIT_BV_SETTINGS,
                graphComponent: GraphComponent.T4Q0Line,
                lineType: PriceChartConst.BV,
                isEnabled: true,
            });
            contextMenuOptions[PriceChartConst.BV].push({
                headerText: ContextMenuConstants.HIDE_BV_LINE,
                action: ContextMenuConstants.HIDE_BV_LINE,
                graphComponent: GraphComponent.T4Q0Line,
                lineType: PriceChartConst.BV,
                isActive: true,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.CFS].push({
                headerText: ContextMenuConstants.EDIT_CFS_SETTINGS,
                action: ContextMenuConstants.EDIT_CFS_SETTINGS,
                graphComponent: GraphComponent.T4Q1Line,
                lineType: PriceChartConst.CFS,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.CFS].push({
                headerText: ContextMenuConstants.HIDE_CFS_LINE,
                action: ContextMenuConstants.HIDE_CFS_LINE,
                graphComponent: GraphComponent.T4Q1Line,
                lineType: PriceChartConst.CFS,
                isActive: true,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.DIV].push({
                headerText: ContextMenuConstants.EDIT_DIV_SETTINGS,
                action: ContextMenuConstants.EDIT_DIV_SETTINGS,
                graphComponent: GraphComponent.T4Q2Line,
                lineType: PriceChartConst.DIV,
                isEnabled: true,
            });
            contextMenuOptions[PriceChartConst.DIV].push({
                headerText: ContextMenuConstants.HIDE_DIV_LINE,
                action: ContextMenuConstants.HIDE_DIV_LINE,
                graphComponent: GraphComponent.T4Q2Line,
                lineType: PriceChartConst.DIV,
                isActive: true,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.FCF].push({
                headerText: ContextMenuConstants.EDIT_FCF_SETTINGS,
                action: ContextMenuConstants.EDIT_FCF_SETTINGS,
                graphComponent: GraphComponent.T4Q3Line,
                lineType: PriceChartConst.FCF,
                isEnabled: true,
            });

            contextMenuOptions[PriceChartConst.FCF].push({
                headerText: ContextMenuConstants.HIDE_FCF_LINE,
                action: ContextMenuConstants.HIDE_FCF_LINE,
                graphComponent: GraphComponent.T4Q3Line,
                lineType: PriceChartConst.FCF,
                isActive: true,
                isEnabled: true,
            });
        }

        /* Zigzag Indicator Context Menu */
        if (![SmartViewType.FUND, SmartViewType.CASHSPOTS, SmartViewType.FUTURES, SmartViewType.CURRENCY, SmartViewType.CRYPTOCURRENCY].includes(SymbolType)) {
            contextMenuOptions[PriceChartConst.ZZ_INDICATOR] = [];

            if (majorPeriodicity === GraphType.Daily || majorPeriodicity === GraphType.Weekly || majorPeriodicity === GraphType.Monthly) {
                contextMenuOptions[PriceChartConst.ZZ_INDICATOR].push({
                    headerText: ContextMenuConstants.EDIT_SETTINGS,
                    action: ContextMenuConstants.EDIT_SETTINGS,
                    graphComponent: GraphComponent.ZZIndicator,
                    lineType: PriceChartConst.ZZ_INDICATOR,
                    isActive: true,
                });

                contextMenuOptions[PriceChartConst.ZZ_INDICATOR].push({
                    headerText: ContextMenuConstants.HIDE_ZZ_INDICATOR,
                    action: ContextMenuConstants.HIDE_ZZ_INDICATOR,
                    graphComponent: GraphComponent.ZZIndicator,
                    lineType: PriceChartConst.ZZ_INDICATOR,
                    isActive: true,
                });
            }
        }

        contextMenuOptions[PriceChartConst.INDEX_LINE] = []
        contextMenuOptions[PriceChartConst.RS1_LINE] = []
        contextMenuOptions[PriceChartConst.RS2_LINE] = []


        contextMenuOptions[PriceChartConst.INDEX_LINE].push({
            headerText: ContextMenuConstants.EDIT_SETTINGS,
            action: ContextMenuConstants.EDIT_INDEX_SETTINGS,
            graphComponent: GraphComponent.IndexLine,
            lineType: PriceChartConst.INDEX_LINE,
            isEnabled: true,
        });

        contextMenuOptions[PriceChartConst.INDEX_LINE].push({
            headerText: ContextMenuConstants.HIDE_INDEX_LINE,
            action: ContextMenuConstants.HIDE_INDEX_LINE,
            graphComponent: GraphComponent.IndexLine,
            lineType: PriceChartConst.INDEX_LINE,
            isActive: true,
            isEnabled: true,
        });
        contextMenuOptions[PriceChartConst.RS1_LINE].push({
            headerText: ContextMenuConstants.EDIT_SETTINGS,
            action: ContextMenuConstants.EDIT_RS1_SETTINGS,
            graphComponent: GraphComponent.RSLine1,
            lineType: PriceChartConst.RS1_LINE,
            isEnabled: true,
        });

        contextMenuOptions[PriceChartConst.RS1_LINE].push({
            headerText: ContextMenuConstants.HIDE_RS1_LINE,
            action: ContextMenuConstants.HIDE_RS1_LINE,
            graphComponent: GraphComponent.RSLine1,
            lineType: PriceChartConst.RS1_LINE,
            isActive: true,
            isEnabled: true,
        });
        contextMenuOptions[PriceChartConst.RS2_LINE].push({
            headerText: ContextMenuConstants.EDIT_SETTINGS,
            action: ContextMenuConstants.EDIT_RS2_SETTINGS,
            graphComponent: GraphComponent.RSLine2,
            lineType: PriceChartConst.RS2_LINE,
            isEnabled: true,
        });

        contextMenuOptions[PriceChartConst.RS2_LINE].push({
            headerText: ContextMenuConstants.HIDE_RS2_LINE,
            action: ContextMenuConstants.HIDE_RS2_LINE,
            graphComponent: GraphComponent.RSLine2,
            lineType: PriceChartConst.RS2_LINE,
            isActive: true,
            isEnabled: true,
        });

        const info = consoleStore.infoList.find((item) => item.featureCode === FeatureModuleType[IndicatorContextConst.Indicator_Panel]);
        contextMenuOptions[IndicatorContextConst.Indicator_Panel] = []
        contextMenuOptions[IndicatorContextConst.Indicator_Panel].push({
            headerText: ContextMenuConstants.ABOUT,
            action: ContextMenuConstants.ABOUT,
            url: info.url,
            title: info.name
        });

        IndicatorSettingTypes.forEach((item) => {
            contextMenuOptions[item] = []

            contextMenuOptions[item].push({
                headerText: IndicatorCommonTranslateHelper[TechnicalIndicator.Settings],
                action: IndicatorContextConst.Indicators,
                selectedOption: TechnicalIndicator.Settings,
                selectedTiTab: IndicatorLabelTranslateHelper[item],
            });
        })
        IndicatorAppearanceTypes.forEach((item) => {
            if (!contextMenuOptions[item]) {
                contextMenuOptions[item] = []
            }

            contextMenuOptions[item].push({
                headerText: IndicatorCommonTranslateHelper[TechnicalIndicator.Appearance],
                action: IndicatorContextConst.Indicators,
                selectedOption: TechnicalIndicator.Appearance,
                selectedTiTab: IndicatorLabelTranslateHelper[item],
            });
        })
        IndicatorAboutTypes.forEach((item) => {
            if (!contextMenuOptions[item]) {
                contextMenuOptions[item] = []
            }

            contextMenuOptions[item].push({
                headerText: IndicatorCommonTranslateHelper[TechnicalIndicator.About],
                action: IndicatorContextConst.Indicators,
                selectedOption: TechnicalIndicator.About,
                selectedTiTab: IndicatorLabelTranslateHelper[item],
            });
        })

        contextMenuOptions[BlockType.ExternalData] = []
        contextMenuOptions[BlockType.ExternalData].push({
            headerText: IndicatorCommonTranslateHelper[TechnicalIndicator.Settings],
            action: IndicatorContextConst.External_Indicators,
        });
        contextMenuOptions[IndicatorContextConst.Indicator_Lines] = {}
        OneilRatingLineTypes.forEach((item) => {
            contextMenuOptions[IndicatorContextConst.Indicator_Lines][item] = {
                headerText: `Hide ${ORatingTranslateHelper[item]}`,
                action: BlockType.TechIndicator,
                lineType: item
            }
        })
        Object.values(IndicatorLineConst).forEach((item) => {
            contextMenuOptions[IndicatorContextConst.Indicator_Lines][item] = {
                headerText: `Hide ${FMIndicatorConst[item]} Estimate`,
                action: BlockType.EPSR,
                lineType: item
            }
        })

        contextMenuOptions[IndicatorContextConst.Indicator_Lines][`${BlockType.PTOE}IndexLine`] = {
            headerText: `Hide Index`,
            action: BlockType.PTOE,
            lineType: `${BlockType.PTOE}IndexLine`
        }
        contextMenuOptions[IndicatorContextConst.Indicator_Lines][`${BlockType.PTOS}IndexLine`] = {
            headerText: `Hide Index`,
            action: BlockType.PTOS,
            lineType: `${BlockType.PTOS}IndexLine`
        }

        yield put({
            type: ActionTypes.UPDATE_CONTEXT_MENU_OPTIONS,
            contextMenuOptions
        });
    } catch (error) {
        console.log(`Error occurs in ContextMenuSaga.js, populateContextMenuItems, ${error}`)
    }

}

/**********************Watchers****************************/
export function* watchPriceContextMenuClick() {
    yield takeLatest(ActionTypes.ON_CONTEXT_OPTION_SELECT, onContextMenuClick);
}
export function* watchPopulateContextMenuItems() {
    yield takeLatest(DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY, populateContextMenuItems);
}

export function* watchPrepareContextMenu() {
    yield takeLatest(ActionTypes.PREPARE_CONTEXTMENU, populateContextMenuItems);
}
