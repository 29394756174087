import GraphType from "../../../Constants/GraphType";

import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import SettingsStore from "SettingsStore";
import RiPanelAiSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelatedInfoPanel/RiPanelAiSettings";
const { ActionTypes } = RiPanelConstants;

const initialState = {
    zigzagIndicatorData: [],
    aiSettings: null,
    isShowZZIndicatorEditDialog: false,
    lineSettings: null
}

const orderedKeys = [GraphType.Daily, GraphType.Weekly, GraphType.Monthly];
const getLineSettingsObj = (lineSettings) => {
    const orderedObject = {};
    orderedKeys.forEach(key => {
        if (lineSettings[key]) {
            orderedObject[key] = JSON.parse(JSON.stringify(lineSettings[key]));
        }
    });

    return orderedObject;
}


const RiPanelAI = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ZZ_INDICATOR_DATA_READY:
            return { ...state, ...action.newState };

        case ActionTypes.UPDATE_ZZ_INDICATOR_SETTINGS:
            state.aiSettings.zigzagIndicator.isVisible = action.isVisible;
            SettingsStore.saveSettings();
            return { ...state };

        case ActionTypes.SHOW_ZZ_INDICATOR_EDIT_DIALOG:
            if (action.isSave) {
                state.aiSettings.zigzagIndicator.lineStyle[GraphType.Daily] = state.lineSettings[GraphType.Daily];
                state.aiSettings.zigzagIndicator.lineStyle[GraphType.Weekly] = state.lineSettings[GraphType.Weekly];
                state.aiSettings.zigzagIndicator.lineStyle[GraphType.Monthly] = state.lineSettings[GraphType.Monthly];
                SettingsStore.saveSettings();

                return { ...state, isShowZZIndicatorEditDialog: action.isShowZZIndicatorEditDialog, lineSettings: null };
            }
            else {
                const lineSettings = getLineSettingsObj(state.aiSettings.zigzagIndicator.lineStyle);

                return { ...state, isShowZZIndicatorEditDialog: action.isShowZZIndicatorEditDialog, lineSettings };
            }

        case ActionTypes.UPDATE_ZZ_INDICATOR_LINE_SETTINGS:
            {
                state.lineSettings[action.activePeriodicity][action.property] = action.value;

                return { ...state, lineSettings: Object.assign({}, state.lineSettings) };
            }

        case ActionTypes.RESTORE_ZZ_INDICATOR_LINE_SETTINGS:
            state.lineSettings[action.activePeriodicity].color = "PFFFFFF000000";
            state.lineSettings[action.activePeriodicity].weight = 2;
            return { ...state, lineSettings: Object.assign({}, state.lineSettings) };

        default: return state;
    }
}

export default RiPanelAI;