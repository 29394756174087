import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import CopyRight from "./CopyRight";
import CorporateEventsDialog from "./PriceMenu/PriceMenuDialog/CorporateEventsDialog";
import EarningsLineAnimationMessageDialog from "./PriceMenu/PriceMenuDialog/EarningsLineAnimationMessageDialog";
import EPSAnimationDialog from "./PriceMenu/PriceMenuDialog/EPSAnimationDialog";
import ExternalDataEditDialog from "../../../../../RayCustomControls/ExternalData/ExternalDataEditDialog";
import FundamentalLineDialog from "./PriceMenu/PriceMenuDialog/FundamentalLineDialog";
import Ideas from "./Ideas";
import IdeasButton from "./IdeasButton";
import IndexLineDialog from "./PriceMenu/PriceMenuDialog/IndexLineDialog";
import MovingAverageSettingDialog from "./PriceMenu/PriceMenuDialog/MovingAverageSettingDialog";
import MovingAverageValidationDialog from "./PriceMenu/PriceMenuDialog/MovingAverageValidationDialog";
import MultiplierMenu from "../../../../../RayCustomControls/ActionButtons/MultiplierMenu";
import OhOl from "./OhOl";
import PatternRecognitionDialog from "./PriceMenu/PriceMenuDialog/PatternRecognitionDialog";
import Pivot from "./Pivot";
import PriceAction from "./PriceAction";
import { PriceChartConst } from "../../../../../Constants/PriceChartConstants";
import PriceMenu from "./PriceMenu";
import { PrintMode } from "../../../../../print/printmode";
import React from 'react';
import RSLineDialog from "./PriceMenu/PriceMenuDialog/RSLineDialog";
import ScaleLabel from './ScaleLabel';
import SmartViewType from "../../../../../Constants/SmartViewType";
import StreetTargetPriceDialog from "./PriceMenu/PriceMenuDialog/StreetTargetPriceDialog";
import { FundamentalLineTranslateHelper, PriceChartTranslateHelper } from "../../../../../Utils/TranslateHelper";
import { priceMenuItemClicked, switchFundametalLineLabel, updateIsEarningsAnimationDocked } from "../../../../../Actions/PricePanelActions";
import ZZIndicatorEditDialog from "./PriceMenu/PriceMenuDialog/ZZIndicatorEditDialog";

const PriceChart = (props) => {
    const { multiplier, selectedPerShare, multiplierPriceMenu } = props;
    const SelectIdeaType = "";
    function onEpsHistoryClicked(e) {
        if (e.button !== 2) {
            // PriceChartStore.SetScrubberdocked(false);
            props.updateIsEarningsAnimationDocked(false);
        }
    }
    return (
        <div className="chartWrapper symbol-chart-wrap">
            {!props.isVolumeOpen && <CopyRight />}
            {<PriceAction />}

            {selectedPerShare && <div className="chart-panel-left-content">
                <Button
                    key='5'
                    bsSize="xsmall"
                    bsStyle="info"
                    data-disable-track-price="true"
                    onClick={() => props.switchLabels(selectedPerShare)}>
                    <span className="with-dot">{selectedPerShare}</span>
                    <div className="auto-search-error pricing-tooltip">{PriceChartTranslateHelper[selectedPerShare]}</div>
                </Button>
                <MultiplierMenu label={`${multiplier[selectedPerShare]} x`}
                    menuTitle={FundamentalLineTranslateHelper[selectedPerShare]}
                    priceMenu={multiplierPriceMenu[selectedPerShare]}
                    shouldDisplayMenu={selectedPerShare === "EXD" ? false : true}
                    onClick={props.priceMenuItemClicked} />
            </div>}
            <div className="chart-panel-center-content">
                <div className="chart-panel-center-content-holder">
                    <div className="pull-right" style={{ position: "relative", right: "10px", pointerEvents: "none" }}>
                        {!props.isIntraday && <Pivot />}
                        <IdeasButton />
                        <OhOl />
                        <Ideas />
                    </div>
                </div>
            </div>
            <div className="chart-panel-right-content">
                <ScaleLabel />
                <PriceMenu />
                {props.SymbolType === SmartViewType.FUND && <span className="prices-date" id="endDateLabel">{props.endDateLabel}</span>}
                {props.IsEarningsAnimationDisplayed && props.IsEarningsAnimationDocked && props.epsScrubberVisiblity && props.epsLineSettings && props.epsLineSettings.IsVisible && !PrintMode.printing &&
                    <div className="btn-eps-animation-block">
                        <div className="icn-btn-eps-animation" onMouseDown={(e) => onEpsHistoryClicked(e)}>EPS History</div>
                    </div>
                }
                <FundamentalLineDialog />
                <MovingAverageSettingDialog />
                <MovingAverageValidationDialog />
                <RSLineDialog />
                <ExternalDataEditDialog />
                <IndexLineDialog />
                <CorporateEventsDialog />
                <EarningsLineAnimationMessageDialog />
                <EPSAnimationDialog />
                <PatternRecognitionDialog />
                <StreetTargetPriceDialog />
                <ZZIndicatorEditDialog />
            </div>
        </div>
    );
};

const mapStateToProps = ({ DatagraphReducers }) => {
    const { multiplier, selectedPerShare, multiplierPriceMenu, IsEarningsAnimationDocked, IsEarningsAnimationDisplayed, fundamentalLineSettings, epsScrubberVisiblity } = DatagraphReducers.FundamentalLineReducer;
    const { rightScaleWidth, isIntraday, SymbolType } = DatagraphReducers.DataGraphReducer;
    const { endDateLabel } = DatagraphReducers.PriceChartReducer;
    const { isVolumeOpen } = DatagraphReducers.volumeChartReducer;
    return {
        multiplier, selectedPerShare, multiplierPriceMenu, IsEarningsAnimationDocked, IsEarningsAnimationDisplayed, epsScrubberVisiblity,
        epsLineSettings: fundamentalLineSettings[PriceChartConst.EPS], rightScaleWidth, isIntraday, isVolumeOpen, endDateLabel, SymbolType
    };
}
const mapDispatchToProps = (dispatch) => ({
    switchLabels: (selectedPerShare) => dispatch(switchFundametalLineLabel(selectedPerShare)),
    priceMenuItemClicked: (item) => dispatch(priceMenuItemClicked(item)),
    updateIsEarningsAnimationDocked: (IsEarningsAnimationDocked) => dispatch(updateIsEarningsAnimationDocked(IsEarningsAnimationDocked)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PriceChart);