class HighLow {
  constructor() {
  }
  Valley(inValues, range, showPct, showPrice) {
    var returnValues = [];
    let inLng = inValues.length;
    if ((inValues == null) || (inLng == 0)) {
      return [];
    }
    for (var eachNode = range - 1; eachNode < inLng - range; eachNode++) {
      var forward = true;
      var backward = true;

      if (inValues[eachNode].TechCalcValue <= 0) continue;

      for (var i = 1; i < range; i++) {
        if (inValues[eachNode].TechCalcValue >= inValues[eachNode + i].TechCalcValue) {
          forward = false;
          break;
        }
        if (!(inValues[eachNode].TechCalcValue > inValues[eachNode - i].TechCalcValue)) continue;
        backward = false;
        break;
      }

      if (forward && backward) {
        returnValues.push({
          NodeDate: inValues[eachNode].TechCalcDate,
          HighLowValue: inValues[eachNode].TechCalcValue,
          IsLow: true,
          showPrice: showPrice,
          showPct: showPct
        });
      }
    }
    return returnValues;
  }
  Peak(values, range, showPct, showPrice) {
    var returnValues = [];
    let inLng = values.length;
    for (var eachNode = range - 1; eachNode < inLng - range; eachNode++) {
      var forward = true;
      var backward = true;

      if (values[eachNode].TechCalcValue <= 0) continue;

      for (var i = 1; i < range; i++) {
        if (values[eachNode].TechCalcValue <= values[eachNode + i].TechCalcValue && eachNode != values.Count - 1) {
          forward = false;
          break;
        }
        if (!(values[eachNode].TechCalcValue < values[eachNode - i].TechCalcValue)) continue;
        backward = false;
        break;
      }

      if (forward && backward) {
        returnValues.push({
          NodeDate: values[eachNode].TechCalcDate,
          HighLowValue: values[eachNode].TechCalcValue,
          IsHigh: true,
          showPrice: showPrice,
          showPct: showPct
        });
      }
    }
    return returnValues;
  }
  compare(a, b) {
    if (a.NodeDate < b.NodeDate) {
      return -1;
    }
    if (a.NodeDate > b.NodeDate) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
  contains(a, obj) {
    var i = a.length;
    while (i--) {
      if (a[i].Date === obj) {
        return i;
      }
    }
    return -1;
  }

  CalculateHighLowNodes(stockQuotesIn, isIntraday, showPct, showPrice, range) {
    if (!stockQuotesIn.allPoints) return;
    let stockQuotes = stockQuotesIn.allPoints;
    let stockQuotesHigh = stockQuotesIn.highPoints;
    let stockQuotesLow = stockQuotesIn.lowPoints;
    var count = stockQuotes.length;
    var highChartPrices = [];
    var lowChartPrices = [];
    var assignIndex = 0;

    for (; assignIndex < count; assignIndex++) {
      if (!stockQuotes[assignIndex]) break;
      var ePrice = stockQuotes[assignIndex].graphData;
      stockQuotes[assignIndex].HiLoPoints = [];
      if (!ePrice.Close || ePrice.Close <= 0)
        continue;
      highChartPrices.push({ TechCalcDate: ePrice.Date, TechCalcValue: ePrice.High });
      lowChartPrices.push({ TechCalcDate: ePrice.Date, TechCalcValue: ePrice.Low });
    }

    var highs = this.Peak(highChartPrices, range, showPct, showPrice);
    var lows = this.Valley(lowChartPrices, range, showPct, showPrice);
    var highLowNodes = highs.concat(lows).sort(this.compare);//              highs.Union(lows).Where(t => t.HighLowValue > 0).OrderBy(t => t.NodeDate).ToList();
    var oldPriceValue = 0.0;
    if (highLowNodes.length < 1) return;
    let stockQuoteIndex;
    var index = 0;
    let hlLng = highLowNodes.length;

    for (; index < hlLng - 1; index++) {
      var nextIndex = index + 1;
      var isCurrentNodeAHighPrice = highLowNodes[index].IsHigh;
      var isNextNodeAHighPrice = highLowNodes[nextIndex].IsHigh;
        var currentNodePrice;
        var nextNodePrice;
        var pctChg;
        if (isCurrentNodeAHighPrice != isNextNodeAHighPrice) {
        // Different nodes
            currentNodePrice = highLowNodes[index].HighLowValue;
            nextNodePrice = highLowNodes[nextIndex].HighLowValue;
            var pctPriceNode = currentNodePrice;
        if (highLowNodes[index].NodeDate == highLowNodes[nextIndex].NodeDate) {
          // Look for the previous high
          for (var i = index; i > 0; i--)
            if (highLowNodes[i].IsHigh && highLowNodes[i].NodeDate != highLowNodes[index].NodeDate) {
              pctPriceNode = highLowNodes[i].HighLowValue;
              break;
            }
        }
        if (showPct) {
            pctChg = ((nextNodePrice - pctPriceNode) / pctPriceNode) * 100;
            //var positiveSign = pctChg > 0.0;
          highLowNodes[index + 1].PercentChanged = pctChg;
          if (Math.abs(pctChg) < 0.01)
            highLowNodes[index + 1].PercentChangedText = "";
          else {
            let strNumberString = "";
            if (isIntraday)
              strNumberString = pctChg.toFixed(2);
            else
              strNumberString = pctChg.toFixed(0);

            if (pctChg > 0.0)
              highLowNodes[index + 1].PercentChangedText = "+" + strNumberString + "%";
            else
              highLowNodes[index + 1].PercentChangedText = strNumberString + "%";
          }
        }
        oldPriceValue = currentNodePrice;
      }
      else {
        // Same nodes
            currentNodePrice = oldPriceValue;
            nextNodePrice = highLowNodes[nextIndex].HighLowValue;
            if (showPct) {
                pctChg = currentNodePrice <= 0 ? 0 : ((nextNodePrice - currentNodePrice) / currentNodePrice) * 100;
                //var positiveSign = pctChg > 0.0;
          highLowNodes[index + 1].PercentChanged = pctChg;
          if (Math.abs(pctChg) < 0.01)
            highLowNodes[index + 1].PercentChangedText = "";
          else {
            let strNumberString = "";
            if (isIntraday)
              strNumberString = pctChg.toFixed(2);
            else
              strNumberString = pctChg.toFixed(0);

            if (pctChg > 0.0)
              highLowNodes[index + 1].PercentChangedText = "+" + strNumberString + "%";
            else
              highLowNodes[index + 1].PercentChangedText = strNumberString + "%";
          }
        }
      }
      stockQuoteIndex = this.contains(stockQuotesHigh, highLowNodes[index].NodeDate);
      if (stockQuoteIndex != -1)
        stockQuotesHigh[stockQuoteIndex].HiLoPoints.push(highLowNodes[index]);
      stockQuoteIndex = this.contains(stockQuotesLow, highLowNodes[index].NodeDate);
      if (stockQuoteIndex != -1)
        stockQuotesLow[stockQuoteIndex].HiLoPoints.push(highLowNodes[index]);
    }
    stockQuoteIndex = this.contains(stockQuotesHigh, highLowNodes[index].NodeDate);
    if (stockQuoteIndex != -1)
      stockQuotesHigh[stockQuoteIndex].HiLoPoints.push(highLowNodes[index]);
    stockQuoteIndex = this.contains(stockQuotesLow, highLowNodes[index].NodeDate);
    if (stockQuoteIndex != -1)
      stockQuotesLow[stockQuoteIndex].HiLoPoints.push(highLowNodes[index]);
  }
}
export default HighLow; 