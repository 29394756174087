import Serializable from "Serializable";
import GraphType from "GraphType";
import PeriodicityHelper from "PeriodicityHelper";

class RiPanelAiSettings extends Serializable {

    constructor() {
        super();
        this.zigzagIndicator = this.getDefaultZigzagIndicatorSettings();
    }

    OnSeralisedInfoRecieved(info) {
        Object.assign(this, info);
    }

    getObjectMapKey() {
        return "RiPanelAiSettings";
    }

    getDataObject() {
        return {
            zigzagIndicator: this.zigzagIndicator
        };
    }

    getDefaultZigzagIndicatorSettings() {
        const z = new Serializable();
        z.isVisible = false;
        z.lineStyle = this.getDefaultLineStyle();
        z.swingSize = this.getDefaultSwingSize();
        return z;
    }

    getDefaultLineStyle() {
        const lineStyle = new Serializable();
        for (let periodicity in GraphType) {
            const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);

            switch (majorPeriodicity) {
                case GraphType.Daily:
                case GraphType.Weekly:
                case GraphType.Monthly:
                    lineStyle[majorPeriodicity] = new Serializable();
                    lineStyle[majorPeriodicity].color = "PFFFFFF000000";
                    lineStyle[majorPeriodicity].weight = 2;
                    break;
                default:
                    break;
            }
        }

        return lineStyle;
    }

    getDefaultSwingSize() {
        const swingSize = new Serializable();
        swingSize.Daily = 10;
        swingSize.Weekly = 15;
        swingSize.Monthly = 20;
        return swingSize;
    }

}

export default RiPanelAiSettings;