/* Common Ri panel info */
export const riPanelInfoState = (state) => state.RelatedInfoPanelReducers.RiPanelReducer;

export const getActiveTab = (state) => riPanelInfoState(state).activeTab;
export const getSymbolType = (state) => riPanelInfoState(state).symbolType;


/* News info */
const newsInfoState = (state) => state.RelatedInfoPanelReducers.RiPanelNewsInfo;
export const getChosenIds = (state) => newsInfoState(state).chosenIds;
export const newsState = (state) => state.RelatedInfoPanelReducers.RiPanelNewsInfo;

/* Check list info */
export const checkListInfoState = (state) => state.RelatedInfoPanelReducers.RiPanelCheckListInfo;

/* Holdings tab */
export const getRiPanelHoldingStates = (state)=> state.RelatedInfoPanelReducers.RiPanelHoldingsReducer;

/* Management Info */
export const getRiPanelManagementState = (state) => state.RelatedInfoPanelReducers.RiPanelManagementInfo;

/* Similar funds */
export const getRiPanelSimilarFundsStates = (state)=> state.RelatedInfoPanelReducers.RiPanelSimilarFundsReducer
/* Contituents tab */
export const getRiPanelConstituentsStates = (state)=> state.RelatedInfoPanelReducers.RiPanelConstituentsReducer


/* Ownership Info */
export const getRiHubPanelState = (state) => state.RelatedInfoPanelReducers.RiPanelHub;
export const getRiPanelOwnershipInfoState = (state) => state.RelatedInfoPanelReducers.RiPanelOwnershipInfo;

/* Allocation Info*/
export const getAllocationState = (state) => state.RelatedInfoPanelReducers.RiPanelAllocationInfo 

/* AI */
export const getRiPanelAIState = (state) => state.RelatedInfoPanelReducers.RiPanelAI;