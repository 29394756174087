import LabelMenu from "LabelMenu";
import { PriceChartConst, PriceChartConstants } from "../../../../../Constants/PriceChartConstants";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiPanelConstants } from "../../../../../Constants/RiPanelConstants";
import { extend } from 'underscore';

const { ActionTypes } = PriceChartConstants;

extend(ActionTypes, RiPanelConstants.ActionTypes);

const PriceMenu = () => {
  const dispatch = useDispatch();
  const onBlockMenuItemClicked = (item) => {
    if (item) {
      dispatch({ type: ActionTypes.PRICE_MENU_ITEM_CLICK, item });
    }
  }

  const onMenuItemClicked = (item) =>{
    if (item) {
      if (item.lineType == PriceChartConst.ZZ_INDICATOR) {
        dispatch({ type: ActionTypes.UPDATE_ZZ_INDICATOR_SWING_SIZE, item })
      } 
      else {
        dispatch({ type: ActionTypes.UPDATE_FUNDAMENTAL_QTR, item })
      }
    }
  }
  const { priceMenu, label } = useSelector((state) => state.DatagraphReducers.PriceMenuReducer);
  const isPricePanelDataReady = useSelector((state) => state.DatagraphReducers.DataGraphReducer.isPricePanelDataReady);
  
  // Added to trigger re-render of the label menu component when visibility or swing size is modified. 
  // This should be removed once the menu option is moved to the Ri Panel.
  const { aiSettings }  = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelAI)
  
  return (isPricePanelDataReady ? <LabelMenu
    label={label}
    priceMenu={isPricePanelDataReady ? priceMenu : null}
    onClick={(item) => onBlockMenuItemClicked(item)}
  // commonStock={showMenu}
  onSelect={(item) => onMenuItemClicked(item)}
  // epsSettings={settings.EarningLineSetting} 
  /> : 
  <div className="symbol-chart-title">
    <pre className="text">
      {label}
    </pre>
  </div>);
}
export default PriceMenu;