import Serializable from "Serializable";
import RiSubCategoryType from "../../../../../../Constants/RiSubCategoryType.js";
import AnnotationSettings from "../AnnotationSettings.js";
import RiPanelAiSettings from "./RiPanelAiSettings.js";

class RiPanelIndustryGroupViewSettings extends Serializable {

    getDefaulSettings() {
        this.ActiveSelectedSubCategory = RiSubCategoryType.Constituents;
        this.AnnotationSettings = this.getDefaultAnnotationSettings();
        this.AiSettings = this.getDefaultAiSettings();
        return this;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.ActiveSelectedSubCategory = serializedValue.ActiveSelectedSubCategory ? serializedValue.ActiveSelectedSubCategory : RiSubCategoryType.Constituents;
        this.AnnotationSettings = serializedValue.AnnotationSettings ? serializedValue.AnnotationSettings : this.getDefaultAnnotationSettings();
        this.AiSettings = serializedValue.AiSettings ? serializedValue.AiSettings : this.getDefaultAiSettings();
    }

    getObjectMapKey() {
        return "RiPanelIndustryGroupViewSettings";
    }

    getDataObject() {
        return {
            ActiveSelectedSubCategory: this.ActiveSelectedSubCategory,
            AnnotationSettings: this.AnnotationSettings,
            AiSettings: this.AiSettings
        };
    }

    getDefaultAnnotationSettings() {
        const annotationSettings = new AnnotationSettings();
        return annotationSettings;
    }

    getDefaultAiSettings() {
        const aiSettings = new RiPanelAiSettings();
        return aiSettings;
    }

}


export default RiPanelIndustryGroupViewSettings;