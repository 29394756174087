let ContextMenuConstants = {
  SHOW_ORIGINAL: 'Show Original',
  ADD_TO_FAVORITES: 'Add To Favorites',
  REMOVE_FROM_FAVORITES: 'Remove From Favorites',
  DUPLICATE: 'Duplicate',
  RENAME: 'Rename',
  DELETE: 'Delete',
  REMOVE: 'Remove',
  AUTO_SIZE_COLUMN_WIDTH: 'Auto-size column width',
  SET_NEW_CUSTOM_ORDER: 'Set New Custom Order',
  REMOVE_COLUMN: 'Remove column',
  REMOVE_ALL_FLAGS: 'Remove all flags',
  FLAG_ALL_SYMBOLS: 'Flag all symbols',
  ABOUT: 'About',
  ALERT : 'Or-condition Alerts',
  DELETE_LIST_ALERTS : 'Delete List Alerts',
  ROW_FLAG: 'Flag',
  ROW_REMOVE_FLAG: 'Remove Flag',
  ROW_COPY: 'Copy',
  ROW_PASTE: 'Paste',
  ROW_SELECT_ALL: 'Select All',
  ROW_REMOVE: 'Remove',
  SAVE_SCREENED_RESULTS: 'Save Screened Results',
  COLUMN_VISUALIZATION: 'Visualization',
  ROTATION_GRAPH_VIEW_SYMBOL: 'View Symbol',
  ROTATION_GRAPH_VIEW_CONSTITUENTS: 'View Constituents',
  ROTATION_GRAPH_RESET_TO_100: 'Reset to 100%',
  ROTATION_GRAPH_FLAG: 'Flag',
  ROTATION_GRAPH_REMOVE_FLAG: 'Remove Flag',
  PRICE_ALERT : 'Price Alert',
  OR_CONDITION_ALERTS : 'Or-condition Alerts',
  AND_CONDITION_ALERTS : 'And-condition Alerts',
  CREATE_MA_ALERT : 'Create MA Alert',
  UPDATE_PRICE_ALERT : 'Edit Alert',
  DELETE_ALERT : 'Delete Alert',
  PRICE_ALERT_TRIGGERED_ACTIVE : 'Reactivate Alert', 
  ALERT_ABOUT : 'Alert About',
  DELETE_ALL_ALERTS_BY_MSIDS : 'Delete Selected Alerts',
  SHARE: 'Share',
  SHIFT_DOWN_SELECTION : 'Shift Arrow Down Selection',
  SHIFT_UP_SELECTION : 'Shift Arrow Up Selection',
  CREATE_CUSTOM_METRIC: 'Create Custom Metric',
  VIEW_METRIC_DEFINITION: 'View Metric Definition',
  EDIT_CUSTOM_METRIC: 'Edit Custom Metric',
  EDIT_ALERT : 'Edit Alerts',
  EDIT_SETTINGS : 'Edit Settings',
  EARNINGS_LINE_ANIMATION : 'Earnings Line Animation',
  EDIT_ANIMATION_SETTINGS : 'edit',
  HIDE_EARNINGS_LINE : 'Hide Earnings Line',
  EDIT_RPS_SETTINGS : 'Edit Settings',
  EDIT_BV_SETTINGS : 'Edit Settings',
  EDIT_CFS_SETTINGS : 'Edit Settings',
  EDIT_DIV_SETTINGS : 'Edit Settings',
  EDIT_FCF_SETTINGS : 'Edit Settings',
  HIDE_RPS_LINE : 'Hide RPS Line',
  HIDE_BV_LINE : 'Hide BV Line',
  HIDE_CFS_LINE : 'Hide CFS Line',
  HIDE_DIV_LINE : 'Hide DIV Line',
  HIDE_FCF_LINE : 'Hide FCF Line',
  CREATE_LINE_ALERT: "Create Line Alert",
  REMOVE_LINE_ALERT: "Remove Line Alert",
  DELETE_SELECTED: "Delete Selected",
  DELETE_ALL: "Delete All",
  MOVE_TO_FRONT: "Move To Front",
  MOVE_TO_BACK: "Move To Back",
  HIDE_MA_LINE: "Hide MA Line",
  ALERT_MENU: "ALERT_MENU",
  PRICE_ACTIVE_TRIGGERED_MENU: "PRICE_ACTIVE_TRIGGERED_MENU",
  PRICE_IN_ACTIVE_TRIGGERED_MENU: "PRICE_IN_ACTIVE_TRIGGERED_MENU",
  MA_ACTIVE_TRIGGERED_MENU: "MA_ACTIVE_TRIGGERED_MENU",
  MA_IN_ACTIVE_TRIGGERED_MENU: "MA_IN_ACTIVE_TRIGGERED_MENU",
  MA_MENU: "MA_MENU",
  EDIT_INDEX_SETTINGS : 'Edit Index Settings',
  HIDE_INDEX_LINE : 'Hide Index Line',
  EDIT_RS1_SETTINGS : 'Edit RS1 Settings',
  HIDE_RS1_LINE : 'Hide RS Line 1',
  EDIT_RS2_SETTINGS : 'Edit RS2 Settings',
  HIDE_RS2_LINE : 'Hide RS Line 2',
  HIDE_ZZ_INDICATOR : 'Hide Zigzag Indicator'
};

export default ContextMenuConstants;

export const TempListMenuItem = { 
  SAVE_RESULTS: 'Save this list',
  REMOVE_RESULT: 'Remove'
};
