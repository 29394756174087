import PropTypes from 'prop-types';
import React from 'react';
import GraphType from "GraphType";
import PeriodicityHelper from "../../Utils/PeriodicityHelper.js";
import ClickMenu from "ClickMenu";
import LocalizationStore from 'LocalizationStore';
import PortalContainer from 'PortalContainer';
import { PriceChartConst } from '../../Constants/PriceChartConstants.js';

export default class LabelMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            priceMenu: props.priceMenu,
            isTICustomModal: props.isTICustomModal,
            isShowSettingsDialog: props.isShowSettingsDialog ? props.isShowSettingsDialog : false,
            isShowAboutDialog: props.isShowAboutDialog ? props.isShowAboutDialog : false,
            isShowAppearanceDialog: props.isShowAppearanceDialog !== undefined ? props.isShowAppearanceDialog : true,
            listId: props.listId !== undefined ? props.listId : null,
            epsSettings: [],
            visibility: 'collapse',
            y: 0,
            x: 0,
            showToolTip: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            priceMenu: nextProps.priceMenu,
            epsSettings: nextProps.epsSettings
        });
    }

    onSelect(obj) {
        this.props.onSelect(obj);
    }

    handleClickMenu(item) {
        this.props.onClick(item);
    }

    renderMenu(options, lineType) {
        const { periodicity, headerKey, quantityKey, quantityLabel, activeItem, valueOptions } = options

        return (
            <div className="fundamental-Lines-Menu">
                <div className="header">
                    {LocalizationStore.getTranslatedData(headerKey, periodicity)}
                </div>
                <div className="itemsDiv">
                    <ul className="fundamental-Lines-Options">
                        {valueOptions.map((item, index) => (
                            <li key={index} className={activeItem === item.value ? 'menuItem active' : ''}>
                                <div onClick={() => this.onSelect({...item, periodicity, lineType})}>
                                    {item.displayValue}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="quantity">
                    {LocalizationStore.getTranslatedData(quantityKey, quantityLabel)}
                </div>
            </div>
        );
    }

    addZZMenu(zzSwingSizeSettings) {

        const menu = [
            {
                headerKey: 'tp_per_dly', periodicity: GraphType.Daily, quantityKey: '', quantityLabel: '%',
                activeItem: zzSwingSizeSettings[GraphType.Daily], 
                valueOptions: [{ value: 5, displayValue: '5' }, { value: 10, displayValue: '10' }, { value: 20, displayValue: '20' }]
            },
            {
                headerKey: 'tp_per_wkly', periodicity: GraphType.Weekly, quantityKey: '', quantityLabel: '%',
                activeItem: zzSwingSizeSettings[GraphType.Weekly], 
                valueOptions: [{ value: 10, displayValue: '10' }, { value: 15, displayValue: '15' }, { value: 20, displayValue: '20' }]
            },
            {
                headerKey: 'tp_per_mnly', periodicity: GraphType.Monthly, quantityKey: '', quantityLabel: '%',
                activeItem: zzSwingSizeSettings[GraphType.Monthly], 
                valueOptions: [{ value: 15, displayValue: '15' }, { value: 20, displayValue: '20' }, { value: 30, displayValue: '30' }]
            },
        ];

        return (
            <div className="estimate-length">
                <h5 className="head">Minimum Swing Size</h5>
                <h6 className="sub-head"></h6>

                {menu.map((options) => this.renderMenu(options, PriceChartConst.ZZ_INDICATOR))}

                <div className="underline-on-hover" onClick={() => this.onSelect({ periodicity: 'All', value: 0, lineType: PriceChartConst.ZZ_INDICATOR })}>
                    {LocalizationStore.getTranslatedData("resdef", "Restore Defaults")}
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }

    addEPSMenu(epsSettings) {

        const menu = [
            {
                headerKey: 'tp_per_dly', periodicity: GraphType.Daily, quantityKey: 'ch_qtrs', quantityLabel: 'qtrs',
                activeItem: epsSettings[GraphType.Daily].NumOfQuarters,
                valueOptions: [{ value: 1, displayValue: '1' }, { value: 2, displayValue: '2' }, { value: 4, displayValue: '4' }]
            },
            {
                headerKey: 'tp_per_wkly', periodicity: GraphType.Weekly, quantityKey: 'ch_qtrs', quantityLabel: 'qtrs',
                activeItem: epsSettings[GraphType.Weekly].NumOfQuarters,
                valueOptions: [{ value: 1, displayValue: '1' }, { value: 2, displayValue: '2' }, { value: 4, displayValue: '4' }, { value: 8, displayValue: '8' }]
            },
            {
                headerKey: 'tp_per_mnly', periodicity: GraphType.Monthly, quantityKey: 'ch_yrs', quantityLabel: 'years',
                activeItem: epsSettings[GraphType.Monthly].NumOfQuarters,
                valueOptions: [{ value: 4, displayValue: '1' }, { value: 8, displayValue: '2' }]
            },
            {
                headerKey: 'tp_per_qly', periodicity: GraphType.Quarterly, quantityKey: 'ch_yrs', quantityLabel: 'years',
                activeItem: epsSettings[GraphType.Quarterly].NumOfQuarters,
                valueOptions: [{ value: 4, displayValue: '1' }, { value: 8, displayValue: '2' }]
            },
            {
                headerKey: 'tp_per_aly', periodicity: GraphType.Annual, quantityKey: 'ch_yrs', quantityLabel: 'years',
                activeItem: epsSettings[GraphType.Annual].NumOfQuarters,
                valueOptions: [{ value: 4, displayValue: '1' }, { value: 8, displayValue: '2' }]
            }
        ];

        return (
            <div className="estimate-length">
                <h5 className="head">{LocalizationStore.getTranslatedData("ch_estlength", "Estimate Length")}</h5>
                <h6 className="sub-head">{LocalizationStore.getTranslatedData("ch_epsrps", "(For EPS and RPS)")}</h6>

                {menu.map((options) => this.renderMenu(options))}

                <div className="underline-on-hover" onClick={() => this.onSelect({ periodicity: 'All', value: 0 })}>
                    {LocalizationStore.getTranslatedData("resdef", "Restore Defaults")}
                </div>
                <div className="clearfix"></div>
            </div>);
    }
    onClick() {
        this.setState({
            visibility: 'visible'
        });
    }
    getSubMenuRender(item) {
        if (item.isDefault) {
            item.onSelect = this.props.onClick;
            return;
        }
        return (this.addEPSMenu(item))
    }
    getZZMenuRender(item) {
        if (item.isDefault) {
            item.onSelect = this.props.onClick;
            return;
        }
        return (this.addZZMenu(item))
    }
    handleMouesOver(e) {
        e.preventDefault();
        if (e.nativeEvent.which === 3) {
            let clientX = e.clientX;
            let clientY = e.clientY;
            this.contextMenuXPos = clientX;
            this.contextMenuYPos = clientY;
            this.props.contextMenuClick(this.props.kpiId);
        }
    }
    mouseEnter(e) {
        this.setState({
            x: window.innerWidth - e.pageX,
            y: e.pageY + 25,
            showToolTip: true
        });
    }
    mouesLeave() {
        this.setState({
            x: 0,
            y: 0,
            showToolTip: false
        });
    }
    render() {
        const isKpi = this.props.isKpiMenu ? this.props.isKpiMenu : false;
        const kpiItem = this.props.item ? this.props.item : null;
        const title = (isKpi && kpiItem.definition) ? kpiItem.definition : '';
        const toolStyle = { right: this.state.x, top: this.state.y };
        return (
            <div id='price' key='1' className={isKpi ? "symbol-chart-title kpi-title" : "symbol-chart-title"}
                onMouseEnter={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.add("open-panel-strip") : '' }}
                onMouseLeave={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.remove("open-panel-strip") : '' }}
                onClick={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.remove("open-panel-strip") : '' }}
                data-disable-track-price="true">
                <pre className="text" onMouseEnter={(e) => { this.mouseEnter(e) }} onMouseLeave={() => { this.mouesLeave() }}>{this.props.label}
                    {((this.state.label.length > 13 || isKpi) && this.state.showToolTip) ? <PortalContainer><span style={toolStyle} className="tooltip tipsy yellow">{isKpi ? title : this.props.label}</span></PortalContainer> : ""}
                    {/* {this.state.priceMenu && this.state.priceMenu.length > 0 && */}
                    <ClickMenu hideOnItemClick={false}
                        data-disable-track-price="true"
                        items={this.state.priceMenu}
                        isTICustomModal={this.state.isTICustomModal}
                        isShowSettingsDialog={this.state.isShowSettingsDialog}
                        isShowAboutDialog={this.state.isShowAboutDialog}
                        selectedTIChart={this.state.label}
                        isShowAppearanceDialog={this.state.isShowAppearanceDialog}
                        listId={this.state.listId}
                        button="left"
                        subMenuItemRender={(item) => this.getSubMenuRender(item) }
                        subMenuItemZZRender={(item) => this.getZZMenuRender(item)}
                        alignment="right"
                        onselect={(item) => this.handleClickMenu(item)}
                        isKpiMenu={isKpi}
                        item={kpiItem}
                    />
                    {/* } */}
                </pre>
            </div>
        );
    }
}
LabelMenu.proptypes = {
    label: PropTypes.string.isRequired,
    priceMenu: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    epsSettings: PropTypes.array,
    onSelect: PropTypes.func,
};