import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Tabs, Tab } from "react-bootstrap";

import { TranslateHelper, LineDialogPeriodicityTranslateHelper } from "../../../../../../../Utils/TranslateHelper";

import { RiPanelConstants } from "../../../../../../../Constants/RiPanelConstants";

import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker";
import { DialogHOC } from "./DialogHOC";

const { ActionTypes } = RiPanelConstants;

const colorPickerWidth = 100;
const thickness = [
    { weight: "thin-line", value: 1 },
    { weight: "normal-line", value: 2 },
    { weight: "weight-line", value: 3 },
    { weight: "bold-line", value: 4 },
    { weight: "heavy-line", value: 5 },
];

const ZZIndicatorEditDialog = ({
    lineSettings,
    majorPeriodicity,
    updateSettings,
    restoreLineSetting
}) => {

    const [activePeriodicity, setPeriodicity] = useState(majorPeriodicity)


    const getTabItemRender = (key, tabitem) => (

        <Tab className="tabItem" key={key} eventKey={key} title={LineDialogPeriodicityTranslateHelper[key]}>
            <div className="fm-data-line">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <h3>{TranslateHelper.Choose_Line_Display_Setting}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="appearance-color">
                                <h5>{TranslateHelper.Color}</h5>
                                <div className="appearance-color-hold">
                                    <ColorPicker
                                        width={colorPickerWidth}
                                        color={tabitem.color}
                                        onChangeComplete={(color) => updateSettings("color", color, activePeriodicity)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="pull-right">
                                <div className="appearance-weight">
                                    <h5>{TranslateHelper.Weight}</h5>
                                    <div className="appearance-weight-hold lining-box">
                                        {thickness.map((item, index) => (
                                            <a
                                                data-disable-track-price="true"
                                                key={index}
                                                className={
                                                    item.value === tabitem.weight
                                                        ? `${item.weight} active`
                                                        : item.weight
                                                }
                                                onClick={() => updateSettings("weight", item.value, activePeriodicity)}
                                            >
                                                <div>
                                                    <hr />
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <span className="rating-default appearance-restore">
                                <a className="link-restore" onClick={() => restoreLineSetting(activePeriodicity)}>
                                    {TranslateHelper.Restore_Defaults}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Tab>
    );

    return (
        <Modal.Body>
            <div className="oneil-rating-box">
                <Tabs
                    id="controlled-tab-example"
                    onSelect={setPeriodicity}
                    activeKey={activePeriodicity}
                >
                    {lineSettings && Object.entries(lineSettings).map(([key, tabitem]) => getTabItemRender(key, tabitem))}
                </Tabs>
            </div>
        </Modal.Body>
    );
};

const mapStateToProps = ({ RelatedInfoPanelReducers, DatagraphReducers }) => {
    const { isShowZZIndicatorEditDialog, lineSettings } = RelatedInfoPanelReducers.RiPanelAI;
    const { majorPeriodicity } = DatagraphReducers.DataGraphReducer;
    return {
        headerClass: "ti-indicators fundamental-data-lines",
        HeaderText: "ZIGZAG INDICATOR",
        showSettingDialog: isShowZZIndicatorEditDialog,
        lineSettings,
        majorPeriodicity,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (property, value, activePeriodicity) => dispatch({ type: ActionTypes.UPDATE_ZZ_INDICATOR_LINE_SETTINGS, property, value, activePeriodicity }),
    saveLineDialog: () => dispatch({ type: ActionTypes.SHOW_ZZ_INDICATOR_EDIT_DIALOG, isShowZZIndicatorEditDialog: false, isSave: true }),
    cancelLineDialog: () => dispatch({ type: ActionTypes.SHOW_ZZ_INDICATOR_EDIT_DIALOG, isShowZZIndicatorEditDialog: false, isSave: false }),
    restoreLineSetting: (activePeriodicity) => dispatch({ type: ActionTypes.RESTORE_ZZ_INDICATOR_LINE_SETTINGS, activePeriodicity })
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(ZZIndicatorEditDialog));
