var GraphComponent = {
  RSLine1: "RSLine1",
  RSLine2: "RSLine2",
  IndexLine: "IndexLine",
  EPSLine: "EPSLine",
  RPSLine: "RPSLine",
  T4Q0Line: "T4Q0Line",
  T4Q1Line: "T4Q1Line",
  T4Q2Line: "T4Q2Line",
  T4Q3Line: "T4Q3Line",
  CorpEvents: "CorpEvents",
  PatternRecognition: "PatternRecognition",
  HiLo: "HiLo",
  HiLoPct: "HiLoPct",
  epsAuto: "epsAuto",
  epsFixed: "epsFixed",
  rpsAuto: "rpsAuto",
  rpsFixed: "rpsFixed",
  t4q0Auto: "t4q0Auto",
  t4q0Fixed: "t4q0Fixed",
  t4q1Auto: "t4q1Auto",
  t4q1Fixed: "t4q1Fixed",
  t4q2Auto: "t4q2Auto",
  t4q2Fixed: "t4q2Fixed",
  t4q3Auto: "t4q3Auto",
  t4q3Fixed: "t4q3Fixed",
  mAverage: "mAverage",
  addMAverage:"addMAverage",
  OpenInterest:"OpenInterest",
  EPSAnimation:"EPSAnimation",
  OwnershipBlockMenuFavorites:"OwnershipBlockMenuFavorites",
  externalData: "externalData",
  StreetTargetPrice: "StreetTargetPrice",
  ZZIndicator: "ZZIndicator"
};

export default GraphComponent;
